import React, { useEffect, useState } from "react";
import ProductList from "components/features/ProductList";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
export const AdminContainer = tw.button`
lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;

export default ({ isAdmin }) => {
  return (
    <AnimationRevealPage>
      <Header />

      <div css="margin-top: 2rem;">
        <ProductList isAdmin={isAdmin} />
      </div>
    </AnimationRevealPage>
  );
};
