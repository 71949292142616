import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import SimplePrimaryBackground from "components/testimonials/SimplePrimaryBackground.js";
import data from "data/product_data.json";
import { getAllTabbedProducts, translateTabs } from "services/productService";
import SearchBar from "./SearchBar/SearchBar";
import { getPageText, getTranslation, translateCategory } from "translation/translationService";
import { LanguageContext } from "context/LanguageContextProvider";
import Dialog from "components/features/Dialog";
import { addProduct } from "services/productService";
import { useHistory, useLocation } from "react-router-dom";
import BG2 from "assets/nature_background_2.png";
import styled, { css } from "styled-components";
import useInView from "@owaiswiz/use-in-view";


const AdminButton = styled(PrimaryButtonBase)((props) => [
  tw` text-sm inline-block mx-auto border-primary-700 border-8 m-0`,
  props.buttonRounded && tw`rounded-full`,
  (props.disabled || props.disableStyles) && tw`bg-gray-100 text-primary-500`,
]);

const Container = styled.div`
  ${tw`relative bg-no-repeat px-6 bg-cover bg-center min-h-screen `}
  ${({ backgroundImage }) => `background-image: url(${backgroundImage})`};
`;
const HeaderTwoRow = tw.div`flex justify-between flex-col`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 bg-opacity-[70%] px-2 py-2 rounded leading-none xl:mt-0`;
const NavigationControl = tw.div`flex items-center justify-items-center bg-gray-200 px-2 py-2 rounded leading-none xl:mt-0`;
const TabCommandWrapper = tw.div`flex items-center bg-opacity-[70%] justify-between flex-wrap bg-gray-200 px-2 py-2 rounded leading-none xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const SearchTab = tw.div`bg-gray-300`;

const TabControl2 = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;
const ControlsContainer = tw.div`sm:ml-auto flex`;

const TabContent = styled(motion.div)`
  ${tw`flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 pt-4`}
  ${({ backgroundImage }) => `background-image: url(${backgroundImage})`};
`;

const CardContainer = tw.div`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12 pt-3`;
const Card = tw(
  motion.a
)`bg-green-100 bg-opacity-[80%] flex flex-col rounded-b flex flex-col max-w-xs mx-auto sm:max-w-none sm:mx-0`;

const CardImageWrapper = styled.div`w-full h-56 xl:h-64 overflow-hidden `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-contain opacity-[80%] bg-no-repeat bg-center mx-4 overflow-hidden relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`px-4 text-gray-900 text-xs flex flex-col`;
const CardTitle = tw.h5`text-sm font-medium group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;

const CardPrice = tw.p`mt-4 text-xs font-bold uppercase flex flex-shrink-0`;
const CardPriceHighlight = tw.p`mt-4 text-xs bg-green-200 px-2 font-bold uppercase flex-shrink-0`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

function ensureTabsArePopulated(tabs) {
  if (Object.keys(tabs).length === 1) {
    tabs.All.forEach((product, index) => {
      if (product.Category) {
        if (tabs[product.Category]) {
          tabs[product.Category].push({ ...product, originalIndex: index });
        } else {
          tabs[product.Category] = [{ ...product, originalIndex: index }];
        }
      }
    });
  }
  return tabs;
}

function generatePaginatedTabs(allData, perPage) {
  const tabs = {};
  allData.forEach((product, originalIndex) => {
    if (product.Category) {
      if (tabs[product.Category]) {
        let index = tabs[product.Category].totalPages - 1;
        if (tabs[product.Category].products[index].length === perPage) {
          tabs[product.Category].totalPages += 1;
          tabs[product.Category].products.push([
            { ...product, originalIndex: originalIndex },
          ]);
        } else {
          tabs[product.Category].products[index].push({
            ...product,
            originalIndex: index,
          });
        }
      } else {
        tabs[product.Category] = {
          products: [[{ ...product, originalIndex: originalIndex }]],
          page: 1,
          totalPages: 1,
          perPage: perPage,
        };
      }
    }

    tabs.All = {
      products: [[{ ...product, originalIndex: originalIndex }]],
      page: 1,
      totalPages: 1,
      perPage: perPage,
    };
    let index = tabs.All.totalPages - 1;
    if (tabs.All.products[index].length === perPage) {
      tabs.All.totalPages += 1;
      tabs.All.products.push([{ ...product, originalIndex: originalIndex }]);
    } else {
      tabs.All.products[index].push({
        ...product,
        originalIndex: index,
      });
    }
  });

  return tabs;
}

function getPagesObject(tabs) {
  const pages = {};
  for (const tab in tabs) {
    pages[tab] = 1;
  }
  return pages;
}


export default ({ isAdmin }) => {
  const [viewRef, inView] = useInView(30);

  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const [product, setProduct] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */

  //   const [tabs, setTabs] = useState(ensureTabsArePopulated(unparsedTabs));
  const [state, dispatch] = useContext(LanguageContext);

  const language = state.language.code;
  const productsText = getTranslation(language, "productPage");

  const heading = productsText.checkoutProducts;
  const pageName = productsText.page;

  const [tabs, setTabs] = useState({"All": []});
  const [rawTabs, setRawTabs] = useState({"All": []});
  const [pages, setPages] = useState();
  const [tabKeys, setTabKeys] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [filter, setFilter] = useState( "");
  const [searchedItems, setSearchedItems] = useState(null);
  const [loading, setLoading] = useState(false);

  function navigatePagesBy(goUp) {
    const currentPage = pages[activeTab];
    if (currentPage > 1 && !goUp) {
      setPages({ ...pages, [activeTab]: pages[activeTab] - 1 });
    } else if (currentPage < tabs[activeTab].totalPages && goUp) {
      setPages({ ...pages, [activeTab]: pages[activeTab] + 1 });
    }
  }

  useEffect(() => {
    setPages(getPagesObject(tabs));
    const tabArray = Object.keys(tabs).filter((tab) => tab !== "All");
    if (tabArray.length > 0) {
      tabArray.unshift("All");
    }
    setTabKeys(tabArray);
    setActiveTab(tabArray[0]);
  }, [tabs]);

  useEffect(() => {
    if (inView) {
      getAllTabbedProducts().then((tabbedProducts) => setRawTabs(tabbedProducts));
    }
  }, [inView]);
  
  useEffect(() => {
    setTabs(translateTabs(rawTabs, language))
    
  }, [language,rawTabs]);

  useEffect(() => {
    if (!filter) {
      setSearchedItems(null);
    } else {
      setLoading(true);
      let filtered = [];
      const products = tabs[activeTab].products;
      for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < products[i].length; j++) {
          if (
            products[i][j]?.title?.toLowerCase().includes(filter?.toLowerCase()) ||
            products[i][j]?.body?.toLowerCase().includes(filter?.toLowerCase())
          ) {
            filtered.push(products[i][j]);
          }
        }
      }
      setLoading(false);
      setSearchedItems(filtered);
    }
  }, [filter, activeTab]);

  function addNewProduct() {
    addProduct(product).then((response) => {
      if (response.handle) {
        history.push(`/product/${response.handle}`);
      } else if (response.error) {
        setErrorMessage(
          "Error adding, title already exists. If error persists, contact Administrator."
        );
      }
    });
  }

  return (
    <Container backgroundImage={BG2} ref={viewRef}>
      {" "}
      {tabs && pages && tabKeys && activeTab ? (
        <ContentWithPaddingXl>
          {isAdmin ? (
            <div>
              <div css="width: 100%; display: flex; align-items:flex-end;">
                <AdminButton
                  className="text-black bg-red-500"
                  onClick={() => setIsAdding(!isAdding)}
                >
                  Add new product
                </AdminButton>
              </div>
              <div css="width: 100%; display: flex; align-items:flex-end;">
                {isAdding ? (
                  <div css="display: flex; justify-content:center; margin-top: 1%;">
                    <div css="display: flex; align-items:center; ">
                      <span>Enter a title:</span>
                    </div>
                    <input
                      css="margin-left: 5px; border: grey 1px solid;"
                      value={product.title}
                      onChange={(e) =>
                        setProduct({ ...product, title: e.target.value })
                      }
                    />
                    <AdminButton
                      css="margin-left: 5px;"
                      cas="a"
                      onClick={addNewProduct}
                    >
                      Add
                    </AdminButton>
                  </div>
                ) : null}
              </div>
              {errorMessage ? errorMessage : null}
            </div>
          ) : null}
          <HeaderTwoRow>
            {/* <Header>{heading}</Header> */}
            <TabsControl>
              {tabKeys.map((tabName, index) => (
                <TabControl
                  key={index}
                  active={activeTab === tabName}
                  onClick={() => setActiveTab(tabName)}
                >
                  {translateCategory(tabName, language)}
                </TabControl>
              ))}
            </TabsControl>
          </HeaderTwoRow>
          <TabCommandWrapper>
            <SearchBar filter={filter} setFilter={setFilter} />
            {!filter ? (
              <NavigationControl>
                {getPageText(pages[activeTab], tabs[activeTab].totalPages, language)}
                <ControlsContainer>
                  <ControlButton>
                    <ArrowLeftIcon
                      className="icon"
                      onClick={() => navigatePagesBy(false)}
                    />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon
                      className="icon"
                      onClick={() => navigatePagesBy(true)}
                    />
                  </ControlButton>
                </ControlsContainer>
              </NavigationControl>
            ) : null}
          </TabCommandWrapper>
          {tabKeys.map((tabKey, index) => (
            <TabContent
            // backgroundImage={BG2}
              key={index}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === tabKey ? "current" : "hidden"}
              animate={activeTab === tabKey ? "current" : "hidden"}
            >
              {searchedItems && !loading
                ? searchedItems.map((card, index) => (
                  <CardContainer key={index}>
                    <Card
                      className="group"
                      href={"/product/" + card.handle}
                      target="_blank"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                    >
                      <CardImageWrapper>
                        <CardImageContainer
                          imageSrc={
                            card.imagesrc ||
                            "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80"
                          }
                        >
                          {card.rating ? (
                            <CardRatingContainer>
                              <CardRating>
                                <StarIcon />
                                {card.rating}
                              </CardRating>
                              <CardReview>({card.reviews})</CardReview>
                            </CardRatingContainer>
                          ) : null}
                          <CardHoverOverlay
                            variants={{
                              hover: {
                                opacity: 1,
                                height: "auto",
                              },
                              rest: {
                                opacity: 0,
                                height: 0,
                              },
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            <CardButton>View Details</CardButton>
                          </CardHoverOverlay>
                        </CardImageContainer>
                        </CardImageWrapper>
                      <CardText>
                        <CardTitle>{card.title || card.handle}</CardTitle>
                        <CardContent></CardContent>
                        <CardPrice>
                          <CardPriceHighlight>{card.category}
                           </CardPriceHighlight>
                          </CardPrice>
                      </CardText>
                    </Card>
                  </CardContainer>
                ))
                : tabs[tabKey].products[pages[tabKey] - 1]
                  .filter(
                    (product) =>
                      !filter ||
                      product.title
                        ?.toLowerCase()
                        .includes(filter?.toLowerCase())
                  )
                  .map((card, index) => (
                    <CardContainer  key={index}>
                      <Card
                        className="group"
                        href={"/product/" + card.handle}
                        target="_blank"
                        initial="rest"
                        whileHover="hover"
                        animate="rest"
                      >
                        <CardImageContainer
                          imageSrc={
                            card.imagesrc ||
                            "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80"
                          }
                        >
                          {card.rating ? (
                            <CardRatingContainer>
                              <CardRating>
                                <StarIcon />
                                {card.rating}
                              </CardRating>
                              <CardReview>({card.reviews})</CardReview>
                            </CardRatingContainer>
                          ) : null}
                          <CardHoverOverlay
                            variants={{
                              hover: {
                                opacity: 1,
                                height: "auto",
                              },
                              rest: {
                                opacity: 0,
                                height: 0,
                              },
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            <CardButton>View Details</CardButton>
                          </CardHoverOverlay>
                        </CardImageContainer>
                        <CardText>
                          <CardTitle>{card.title || card.handle}</CardTitle>
                          <CardContent></CardContent>
                          <CardPrice>
                          <CardPriceHighlight>{card.category}
                           </CardPriceHighlight>
                          </CardPrice>                    
                              </CardText>
                      </Card>
                    </CardContainer>
                  ))}
            </TabContent>
          ))}
        </ContentWithPaddingXl>
      ) : null}
      <DecoratorBlob1 />
      <DecoratorBlob2 />{" "}
    </Container>
  );
};
