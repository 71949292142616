import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import { brandIcons } from "./BrandIcons";
import ThreeColSimpleWithImage from "components/blogs/ThreeColSimpleWithImage";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TabCardGrid from "components/cards/TabCardGrid.js";
import MapImageUrl from 'assets/map2_540x.png';
import data from 'data/product_data.json';
import ProductDisplay from "components/features/ProductDisplay";
import ProductList from "components/features/ProductList";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Guard  <HighlightedText>Your Heart</HighlightedText></>}
        description="Fully experience good health by providing the finest supplements, foods, and lifestyle solution."
        imageSrc="https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Chefs"
      />                
      <div style={{justifyContent: 'center', flexWrap: 'wrap', display: 'flex'}}>
        {brandIcons.map((brandName) => {
          return <div style={{ display: 'flex', position:'relative', alignItems: 'center' }}>
            <img style={{transform: 'scale(0.5)'}} src={brandName} alt='brand image' />
        </div >
        })}
      </div>        
      <MainFeature2
        subheading={<Subheading>A Reputed Store</Subheading>}
        heading={<>Where <HighlightedText>Are We ?</HighlightedText></>}
        description={[
          '',
          'Mon - Fri, 10am - 6pm', 
          'Saturday, 11am - 6pm', 
          'Sunday, Closed']
         }
        statistics={[
          {
            key:  '550 Hwy 7 #58, Richmond Hill',
            value: 'ON L4B 3Z4',
          }
        ]}
        primaryButtonText="Get Directions"
        primaryButtonUrl="https://www.google.com/maps/dir//550+Hwy+7,+Richmond+Hill,+ON+L4B+3Z4/@43.8453915,-79.4539783,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882b2b4bb036830f:0x2d49881ef76c3a13!2m2!1d-79.3839385!2d43.8454129"
        imageInsideDiv={true}
        imageSrc={MapImageUrl}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <ProductList unparsedTabs={{All: data}} />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>ComeForHealth App.</HighlightedTextInverse></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
