import Redsenol from "assets/brandLogos/Redsenol.jpg";
import allKiDz from "assets/brandLogos/AllKidz.png";
import AVogel from "assets/brandLogos/AVogel.png";
import GardenOfLife from "assets/brandLogos/GardenOfLife.png";
import Purica from "assets/brandLogos/Purica.png";
import RenewLife from "assets/brandLogos/RenewLife.png";
import SiSU from "assets/brandLogos/SiSU.png";

export const brandIcons = [
  Redsenol,
  allKiDz,
  AVogel,
  GardenOfLife,
  Purica,
  RenewLife,
  SiSU,
];
