/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment, useContext } from "react";
import tw from "twin.macro";
import { LanguageContext } from "context/LanguageContextProvider";

const Box = tw.div`h-[40px] text-black/50 flex items-center justify-end py-1 absolute top-0 right-[30px] bg-white bg-opacity-[30%]`;
const TranslateButton = tw.button`ml-1 flex items-center justify-center inline-block hover:text-bhttp://localhost:3000/static/media/homeImageMedium.e52baba66f8c9061cdee.jpglack/50`;

// export default function LanguageDropdown() {
//   return (
//     <div>
//       <button
//         css={tw`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center`}
//         type="button"
//         data-dropdown-toggle="dropdown"
//       >
//         Dropdown button{" "}
//         <svg
//           css={tw`w-4 h-4 ml-2`}
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             stroke-linecap="round"
//             stroke-linejoin="round"
//             stroke-width="2"
//             d="M19 9l-7 7-7-7"
//           ></path>
//         </svg>
//       </button>

//       <div
//         css={tw` bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4`}
//         id="dropdown"
//       >
//         <div css={tw`px-4 py-3`}>
//           <span css={tw`block text-sm`}>Bonnie Green</span>
//           <span css={tw`block text-sm font-medium text-gray-900 truncate`}>
//             name@flowbite.com
//           </span>
//         </div>
//         <ul css={tw`py-1`} ariaLabelledby="dropdown">
//           <li>
//             <a
//               href="#"
//               css={tw`text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2`}
//             >
//               Dashboard
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               css={tw`text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2`}
//             >
//               Settings
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               css={tw`text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2`}
//             >
//               Earnings
//             </a>
//           </li>
//           <li>
//             <a
//               href="#"
//               css={tw`text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2`}
//             >
//               Sign out
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// }

export default () => {
  const [state, dispatch] = useContext(LanguageContext);

  function changeLanguage(code) {
    dispatch({
      type: "Change_To",
      payload: { code },
    });
  }

  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.355-6.048v-.105c0-.922 0-1.343-.652-1.716a7.374 7.374 0 0 0-.645-.325c-.367-.167-.61-.276-.938-.756a12.014 12.014 0 0 1-.116-.172c-.345-.525-.594-.903-1.542-.753-1.865.296-2.003.624-2.085 1.178l-.013.091c-.121.81-.143 1.082.195 1.437 1.265 1.327 2.023 2.284 2.253 2.844.112.273.4 1.1.202 1.918a8.185 8.185 0 0 0 3.151-2.237c.11-.374.19-.84.19-1.404zM12 3.833c-2.317 0-4.41.966-5.896 2.516.177.123.331.296.437.534.204.457.204.928.204 1.345 0 .328 0 .64.105.865.144.308.766.44 1.315.554.197.042.399.084.583.135.506.14.898.595 1.211.96.13.151.323.374.42.43.05-.036.211-.211.29-.498.062-.22.044-.414-.045-.52-.56-.66-.529-1.93-.356-2.399.272-.739 1.122-.684 1.744-.644.232.015.45.03.614.009.622-.078.814-1.025.949-1.21.292-.4 1.186-1.003 1.74-1.375A8.138 8.138 0 0 0 12 3.833z"
          fill="rgba(137,195,65,1)"
        />
      </svg>{" "}
      {state.language.code === "en" ? (
        <TranslateButton onClick={() => changeLanguage("cn")}>
          English
        </TranslateButton>
      ) : (
        <TranslateButton onClick={() => changeLanguage("en")}>
          Chinese [中文]
        </TranslateButton>
      )}
    </Box>
  );
};
