import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./StyleObjects/TextEditor.scss";
import { ReactComponent as BoldSvg } from "assets/icons/bold.svg";
import { ReactComponent as ItalicSvg } from "assets/icons/italic.svg";
import { ReactComponent as StrikethroughSvg } from "assets/icons/strikethrough.svg";
import { ReactComponent as CodeViewSvg } from "assets/icons/code-view.svg";
import { ReactComponent as H1Svg } from "assets/icons/h-1.svg";
import { ReactComponent as H2Svg } from "assets/icons/h-2.svg";
import { ReactComponent as ParagraphSvg } from "assets/icons/paragraph.svg";
import { ReactComponent as ListUnorderedSvg } from "assets/icons/list-unordered.svg";
import { ReactComponent as ListOrderedSvg } from "assets/icons/list-ordered.svg";
import { ReactComponent as ListCheckSvg } from "assets/icons/list-check-2.svg";
import { ReactComponent as CodeBoxLineSvg } from "assets/icons/code-box-line.svg";
import { ReactComponent as ChatQuoteLineSvg } from "assets/icons/chat-quote-line.svg";
import { ReactComponent as SeparatorSvg } from "assets/icons/separator.svg";
import { ReactComponent as TextWrapSvg } from "assets/icons/text-wrap.svg";
import { ReactComponent as FormatClearSvg } from "assets/icons/format-clear.svg";
import { ReactComponent as ArrowGoBackSvg } from "assets/icons/arrow-go-back-line.svg";
import { ReactComponent as ArrowGoForwardSvg } from "assets/icons/arrow-go-forward-line.svg";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="header-container">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={
          "header-button " + (editor.isActive("bold") ? "is-active" : "")
        }
      >
        <BoldSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={
          "header-button " + (editor.isActive("italic") ? "is-active" : "")
        }
      >
        <ItalicSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={
          "header-button " + (editor.isActive("strike") ? "is-active" : "")
        }
      >
        <StrikethroughSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={
          "header-button " + (editor.isActive("code") ? "is-active" : "")
        }
      >
        <CodeViewSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={
          "header-button " + (editor.isActive("paragraph") ? "is-active" : "")
        }
      >
        <ParagraphSvg />
      </button>
      <div className="divider" />
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          "header-button " +
          (editor.isActive("heading", { level: 1 }) ? "is-active" : "")
        }
      >
        <H1Svg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          "header-button " +
          (editor.isActive("heading", { level: 2 }) ? "is-active" : "")
        }
      >
        <H2Svg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={
          "header-button " + (editor.isActive("bulletList") ? "is-active" : "")
        }
      >
        <ListUnorderedSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={
          "header-button " + (editor.isActive("orderedList") ? "is-active" : "")
        }
      >
        <ListOrderedSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={
          "header-button " + (editor.isActive("codeBlock") ? "is-active" : "")
        }
      >
        <CodeBoxLineSvg />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={
          "header-button " + (editor.isActive("blockquote") ? "is-active" : "")
        }
      >
        <ChatQuoteLineSvg />
      </button>
      <div className="divider" />
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        <separator />
      </button>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}>
        <TextWrapSvg />
      </button>
      <div className="divider" />
      <button onClick={() => editor.chain().focus().undo().run()}>
        <ArrowGoBackSvg />
      </button>
      <button onClick={() => editor.chain().focus().redo().run()}>
        <ArrowGoForwardSvg />
      </button>
    </div>
  );
};

export default ({ html, editMode, setState }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    editable: editMode,
    content: html,
    onUpdate() {
      const updatedData = this.getHTML();
      setState(updatedData);
    },
  });

  useEffect(() => {
    if (editor) {
      editor.setOptions({ editable: editMode });
    }
  }, [editMode]);

  useEffect(() => {
    if (editor) {
      editor.chain().setContent(html).run();
    }
  }, [html]);


  return (
    <div className={`text-editor-container${editMode ? "-editing" : ""}`}>
      {editMode ? <MenuBar editor={editor} /> : null}
      <EditorContent className="text-display-container" editor={editor} />
    </div>
  );
};
