export const cn = {
  name: "ComeForHealth",
  dropdown: {
    products: "產品",
  },
  homePage: {
    homeHeader1: "守護",
    homeHeader2: "你的心",
    homeHeaderDescription:
      "通過提供最好的補充劑、食物和生活方式解決方案，充分體驗健康",
    productLink: "查看我們的產品",
    expoloreOur: "探索我們精選的品牌",
    redsenol: {
      subtitle: "品牌獨家",
      title: "信賴我們的品牌。",
      description: "我們是多倫多唯一被授權的Redsenol經銷商。"
    },
    cnpa: {
      subtitle: "CNPA認證",
      title: "我們懂得我們的專業！",
      description: "員工接受來自加拿大自然療法醫學院的基於科學的教育。"
    },
    where1: "商店",
    where2: "在哪裡?",
    closest: "最靠近入口 3",
    contactUs1: "公司",
    contactUs2: "聯繫方式",
    contactUs: "如何聯絡我",
    phoneNumber: "電話號碼:",
    email: "電子郵件:",
    reputedStore: "知名商店",
    getDirections: "獲取路線",
    daysOfWeek: [
      "週一: 11:00-20:00",
      "週二: 11:00-20:00",
      "週三: 11:00-20:00",
      "週四: 11:00-20:00",
      "週五: 11:00-20:00",
      "週六: 11:00-18:00",
      "週日: 11:00-18:00",
    ],
  },
  productPage: {
    checkoutProducts: "查看我們的產品",
    page: "頁"
  },
  productDisplay: {
    ourProduct: "我們的產品",
  },
  search: {
    search: "搜尋",
  }
};
