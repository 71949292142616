import React, { useContext, useEffect, useMemo, useState } from "react";
import "./StyleObjects/SearchBar.scss";
import debounce from "lodash.debounce";
import tw from "twin.macro";
import SearchIcon from "./SearchIcon";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useLocation } from "react-router-dom";
import { set } from "lodash";
import { LanguageContext } from "context/LanguageContextProvider";
import { getTranslation } from "translation/translationService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ({  setFilter }) => {
  let query = useQuery();
  let brandQuery = query.get("brand");
  const [input, setInput] = useState("");
  const [state] = useContext(LanguageContext);
  const language = state.language.code;
  const searchTranslations = getTranslation(language, "search");

  const handleChange = (val) => {
    setFilter(val);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);
  
  useEffect(() => {
    debouncedResults(input);
  }, [input])

  useEffect(() => {
    if (brandQuery) {
      setInput(brandQuery);
    }

    return () => {
      debouncedResults.cancel();
    };
  }, []);

  return (
    <div className="search-bar-container" css={tw`flex justify-start items-start`}>
      <form css={tw`flex items-start`}>
        <label for="simple-search" css={tw`sr-only`}>
          {searchTranslations.search}
        </label>
        <div css={tw`relative w-full`}>
          <div
            css={tw`flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none`}
          ></div>
          <div css={tw`flex items-center`}>
            <input
              type="text"
              id="simple-search"
              css={tw`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder={searchTranslations.search}
              onChange={(event) => setInput(event.target.value)}
              value={input}
              required
            ></input>
          </div>
        </div>
        <button
          type="submit"
          disabled
          css={tw`p-2 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
        >
          <svg
            css={tw`w-4 h-4`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </button>
      </form>
    </div>
  );
};
