export const en = {
  name: "ComeForHealth",
  dropdown: {
    products: "Products",
  },
  homePage: {
    homeHeader1: "Guard",
    homeHeader2: "Your Heart",
    homeHeaderDescription:
      "Fully experience good health by providing the finest supplements, foods, and lifestyle solution.",
    productLink: "View Our Products",
    expoloreOur: "Explore Our Curated Selection of Brands",
    redsenol: {
      subtitle: "Brand Exclusive",
      title: "Trust in our brand.",
      description: "We are the only authorized dealer in all of Toronto for Redsenol."
    },
    cnpa: {
      subtitle: "CNPA Certified",
      title: "We know our stuff!",
      description: "Staff being versed in science-based education from Canadian College of Naturopathic Medicine.",
    },
    where1: "Where",
    where2: "Are We?",
    closest: "Closest to Entrance 3",
    contactUs1: "Contact",
    contactUs2: "Us",
    phoneNumber: "Phone Number:",
    email: "Email:",
    reputedStore: "A reputed Store",
    getDirections: "Get Directions",
    daysOfWeek: [
      "Monday: 10am-8pm",
      "Tuesday: 10am-8pm",
      "Wednesday: 10am-8pm",
      "Thursday: 10am-8pm",
      "Friday: 10am-8pm",
      "Saturday: 11am-6pm",
      "Sunday: 11am-6pm",
    ],
  },
  productPage: {
    checkoutProducts: "Products",
    page: "Page"
  },
  productDisplay: {
    ourProduct: "Our Product",
  },
  search: {
    search: "Search",
  }
};
