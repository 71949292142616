import Redsenol from "./brandLogos/Redsenol.jpg";
import AVogel from "./brandLogos/AVogel.png";
import GardenOfLife from "./brandLogos/GardenOfLife.png";
import Purica from "./brandLogos/Purica.png";
import AllKiDz from "./brandLogos/AllKidz.png";
import HairEssentials from "./brandLogos/HairEssentials.jpg";
import Aor from "./brandLogos/Aor.png";
import MegaFood from "./brandLogos/MegaFood.png";
import RenewLife from "./brandLogos/RenewLife.png";
import BackToEarth from "./brandLogos/BackToEarth.png";
import NewChapter from "./brandLogos/NewChapter.png";
import SiSU from "./brandLogos/SiSU.png";
import Doms from "./brandLogos/Doms.jpg";
import NordicNaturals from "./brandLogos/NordicNaturals.jpg";
import SouthFrance from "./brandLogos/SouthFrance.jpg";
import DrNatural from "./brandLogos/DrNatural.jpg";
import Provita from "./brandLogos/Provita.jpg";
import Cellex from "./brandLogos/Cellex.png";

export const brandIcons = [
  { image: Cellex, name: "Cellex" },
  { image: Redsenol, name: "Redsenol" },
  { image: AVogel, name: "A.Vogel" },
  { image: GardenOfLife, name: "Garden Of Life" },
  { image: Purica, name: "Purica" },
  { image: AllKiDz, name: "AllKiDz" },
  { image: HairEssentials, name: "Hair Essentials" },
  { image: Aor, name: " Aor" },
  { image: MegaFood, name: "MegaFood" },
  { image: RenewLife, name: "Life®" },
  { image: NewChapter, name: "New Chapter" },
  { image: SiSU, name: "SiSU" },
  { image: NordicNaturals, name: "Nordic Natural" },
  { image: SouthFrance, name: "South of France" },
  { image: Doms },
  { image: BackToEarth},
  { image: DrNatural},
  { image: Provita },
];
