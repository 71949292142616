import React from "react";
import tw from "twin.macro";

const Container = tw.div`fixed z-10 inset-0 overflow-y-auto`;
const Container2 = tw.div`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`;
const Container3 = tw.div`flex fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity items-center justify-center `;
const Container4 = tw.div`bg-gray-500 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`;
const CancelProduct = tw.div`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`;
const DeleteProduct = tw.div`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`;

export default ({
  isOpen,
  setIsDialogOpen,
  deleteProduct,
  action = "delete",
}) => {
  return isOpen ? (
    <Container ariaLabelledby="modal-title" role="dialog" aria-modal="true">
      <Container2>
        <Container3 aria-hidden="true">
          <Container4>
            <DeleteProduct type="button" onClick={deleteProduct}>
              {action.toUpperCase()}
            </DeleteProduct>
            <CancelProduct
              type="button"
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              Cancel
            </CancelProduct>
            <span>Cancel or {action} the product?</span>
          </Container4>
        </Container3>
      </Container2>
    </Container>
  ) : (
    <div> </div>
  );
};
