import { en } from "./en";
import { cn } from "./cn";

export function getTranslation(language, key) {
  if (language == "en") {
    return en[key];
  } else if (language == "cn") {
    return cn[key];
  }
}
export function translateCategory(category, language) {
  // Define a mapping of category translations for different languages
  const categoryTranslations = {
    'en': {
      'all': 'All',
      'sleep & stress': 'Sleep & Stress',
      'immune system': 'Immune System',
      'hormone': 'Hormone',
      'beauty': 'Beauty',
      'children`s health': 'Children`s Health',
      'organs': 'Organs',
      'antioxidants': 'Antioxidants',
      'weight control': 'Weight Control',
      'heart & cardiovascular': 'Heart & Cardiovascular',
      'mental energy': 'Mental Energy',
      'vitamins & minerals': 'Vitamins & Minerals',
      'bone &joint': 'Bone & Joint',
      'fatty acids': 'Fatty Acids',
      'digestion': 'Digestion',
      'eye care': 'Eye Care',
      'blood sugar control': 'Blood Sugar Control',
      'household': 'HouseHold',
      // Add more English translations here
    },
    'cn': {
      'all': '全部',
      'sleep & stress': '睡眠与压力',
      'immune system': '免疫系统',
      'hormone': '激素',
      'beauty': '美容',
      'children`s health': '儿童健康',
      'organs': '器官',
      'antioxidants': '抗氧化剂',
      'weight control': '体重控制',
      'heart & cardiovascular': '心脏与心血管',
      'mental energy': '精神能量',
      'vitamins & minerals': '维生素与矿物质',
      'bone &joint': '骨骼与关节',
      'fatty acids': '脂肪酸',
      'digestion': '消化',
      'eye care': '眼部护理',
      'blood sugar control': '血糖控制',
      'household': '家庭用品',
      // Add more Traditional Chinese translations here
    },
    // Add more language translations here
  };

  // Convert the category to lowercase for case-insensitive comparison
  const lowerCategory = category.toLowerCase();

  // Check if the language is supported
  if (categoryTranslations.hasOwnProperty(language)) {
    // Check if the lowercased category exists in the language's mapping
    if (categoryTranslations[language].hasOwnProperty(lowerCategory)) {
      // Return the translated category
      return categoryTranslations[language][lowerCategory];
    } else {
      // If the category is not found, return it as is
      return category;
    }
  } else {
    // If the language is not supported, return the category as is
    return category;
  }
}



export function translateInformationTabs(tab, language) {
  // Define a mapping of tab translations for different languages
  const tabTranslations = {
    'en': {
      'ingredients': 'Ingredients',
      'suggested use': 'Suggested Use',
      // Add more English translations here
    },
    'cn': {
      'ingredients': '成分',
      'suggested use': '建议用法',
      // Add more Traditional Chinese translations here
    },
    // Add more language translations here
  };

  // Convert the tab to lowercase for case-insensitive comparison
  const lowerTab = tab.toLowerCase();

  // Check if the language is supported
  if (tabTranslations.hasOwnProperty(language)) {
    // Check if the lowercased tab exists in the language's mapping
    if (tabTranslations[language].hasOwnProperty(lowerTab)) {
      // Return the translated tab
      return tabTranslations[language][lowerTab];
    } else {
      // If the tab is not found, return it as is
      return tab;
    }
  } else {
    // If the language is not supported, return the tab as is
    return tab;
  }
}

export function getPageText(page, total, language) {
  let text;
  
  switch (language) {
    case 'cn':
      text = `第${page}頁，共${total}頁`;
      break;
    case 'en':
    default:
      text = `Page ${page} of ${total}`;
  }
  
  return text;
}